






























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Watch } from 'vue-property-decorator';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { apiStatus } from '@/store';
import JwlButton from '@/components/JwlButton.vue';

const IconEnvelope = () => import('common-modules/src/assets/fa-icons/light/envelope.svg');
const IconUserFriends = () => import('common-modules/src/assets/fa-icons/light/user-friends.svg');
const IconSync = () => import('common-modules/src/assets/fa-icons/duotone/spinner-third.svg');
const CommonError = () => import('common-modules/src/components/CommonError.vue');
const LmsContactProfile = () => import('./LmsContactProfile.vue');
const LmsContactSync = () => import('./LmsContactSync.vue');

export interface UserContactResponse {
  name: string;
  eMail: string;
  role: string;
  profilePicture: string;
  eMailChanged: boolean;
}

const props = Vue.extend({
  props: {
    contactUser: Number,
    contactClassroom: Number,
    courseId: Number,
    otherClassroom: Number,
    targetRole: String,
  },
});

@Component({
  components: {
    IconEnvelope,
    IconUserFriends,
    IconSync,
    LmsContactProfile,
    LmsContactSync,
    CommonError,
    JwlButton,
  },
})
export default class LmsContactOverlay extends props {
  $refs!: {
    gradebookContactForm: HTMLFormElement
    scrollContainer: HTMLDivElement
  }

  API_STATUS = apiStatus
  colleagues = []
  editor = ClassicEditor
  editorConfig = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
  }

  error = null
  facilitators = []
  fadeIn = false
  groupContact: UserContactResponse[] | null = null
  initialLoadState = apiStatus.STATUS_FETCHING
  programmeUserRole = 'student'
  sendStatus = apiStatus.STATUS_IDLE
  sendToColleagues = false
  sendToFacilitators = false
  sendToStudent = true
  singleContact: UserContactResponse | null = null
  staticFooter = ''
  staticFooterSync = apiStatus.STATUS_IDLE
  subject = ''
  subjectTemplate = ''
  text = ''

  get rootModifier (): Record<string, boolean> {
    return {
      'lms-contact-overlay--fade-in': this.fadeIn,
    };
  }

  get showForm (): boolean {
    return this.initialLoadState === apiStatus.STATUS_SUCCESS
      && this.sendStatus === apiStatus.STATUS_IDLE;
  }

  get hasColleagues (): boolean {
    return this.colleagues.length > 0;
  }

  get hasFacilitators (): boolean {
    return this.facilitators.length > 0;
  }

  get hasOptions (): boolean {
    return (this.hasColleagues || this.hasFacilitators) && this.programmeUserRole !== 'student';
  }

  get footerSyncClasses (): Record<string, boolean> {
    return {
      'lms-contact-overlay__static-footer-sync--visible': this.staticFooterSync !== apiStatus.STATUS_IDLE,
    };
  }

  evaluateForm (withoutAttachment = false): FormData {
    const fd = new FormData(this.$refs.gradebookContactForm);
    fd.append('text', this.text);

    if (withoutAttachment) {
      fd.delete('attachment');
    }

    return fd;
  }

  baseUrl (controllerActionPath = 'details'): string {
    let baseUrl = `contact-message/${controllerActionPath}/${this.courseId}/${this.contactClassroom}`;

    if (this.contactUser) {
      baseUrl += `/single/${this.contactUser}`;
    } else {
      baseUrl += `/all/${this.targetRole}`;

      if (this.otherClassroom) {
        baseUrl += `/classroom/${this.otherClassroom}`;
      }
    }

    return baseUrl;
  }

  fetchBaseData (): void {
    this.$store.dispatch('getData', this.baseUrl())
      .then((data) => {
        this.subject = data.subjectTemplate;
        this.subjectTemplate = data.subjectTemplate;
        this.colleagues = data.colleagues;
        this.facilitators = data.other_classroom;
        this.staticFooter = data.staticFooter;
        this.programmeUserRole = data.programmeUserRole;

        if (data.contact_single) {
          this.singleContact = data.contact_single;
        } else {
          this.groupContact = data.contact_group;
        }

        this.initialLoadState = apiStatus.STATUS_SUCCESS;
      })
      .catch((e) => {
        this.error = e;
        this.initialLoadState = apiStatus.STATUS_ERROR;
      });
  }

  @Watch('sendToStudent')
  @Watch('sendToFacilitators')
  @Watch('sendToColleagues')
  fetchStaticFooter (): void {
    this.staticFooterSync = apiStatus.STATUS_FETCHING;
    this.$store.dispatch('postData', {
      url: this.baseUrl('static-footer'),
      formData: this.evaluateForm(true),
    }).then((data) => {
      this.staticFooter = data.static_footer;
      this.staticFooterSync = apiStatus.STATUS_IDLE;
    });
  }

  sendMessage (): void {
    this.sendStatus = apiStatus.STATUS_FETCHING;
    this.$store.dispatch('postData', {
      url: this.baseUrl(),
      formData: this.evaluateForm(),
    }).then(() => {
      this.sendStatus = apiStatus.STATUS_SUCCESS;
    }).catch((e) => {
      this.error = e;
      this.sendStatus = apiStatus.STATUS_ERROR;
    });
  }

  closeOverlay (): void {
    this.fadeIn = false;
  }

  handleTransitionEnd (): void {
    if (!this.fadeIn) {
      if (this.$refs.scrollContainer) {
        enablePageScroll();
      }
      this.$emit('close-overlay');
    }
  }

  mounted (): void {
    this.fetchBaseData();
    setTimeout(() => {
      this.fadeIn = true;
      if (this.$refs.scrollContainer) {
        disablePageScroll(this.$refs.scrollContainer);
      }
    }, 100);
  }
}
